import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, Scene } from 'react-scrollmagic';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

interface props {
	'pageId': string
}

function Text(props: props) {
	const navigate = useNavigate();
	
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {			
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);
	
	if (_.isEmpty(text)) { return null; }

	return (
		<main className="PAGE">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={ lang } />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={ text.pageDescription } />
			</Helmet>

			<section className="SECTION">
				<Controller globalSceneOptions={{
					'triggerHook': 0.95
				}}>
					<div className="grid">
						{
							text.title &&
							<div className="row">
								<div className="col-12 col-md-10 push-md-1">
									<Scene classToggle="animationTriggered">
										<h1 className="SECTION__title elFadeUp delay-xs">
											{ text.title }
										</h1>
									</Scene>
								</div>
							</div>
						}
								
						{
							text.text &&
							<div className="row">
								<div className="col-12 col-md-8 push-md-2">
									<Scene classToggle="animationTriggered">
										<div className="SECTION__content elFadeUp delay-sm">
											<div className="SECTION__text" dangerouslySetInnerHTML={{__html: text.text}}></div>
										</div>
									</Scene>
								</div>
							</div>
						}
					</div>
				</Controller>
			</section>
		</main>
	);
}

export default Text;