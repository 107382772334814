import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Controller, Scene } from 'react-scrollmagic';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import _ from 'lodash';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { checkCookie } from './../../other/helpers/cookies';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import PageBg from './../../components/page-bg/page-bg';
import ProjectItem from './../../components/project-item/project-item';
import ProgressiveImg from './../../components/progressive-img/progressive-img';

import './project.scss';

interface props {
	'pageId': string
}

function Project(props: props) {
	const navigate = useNavigate();
	const { slug } = useParams();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});
	const [project, setProject] = useState<{[key: string]: any}>({});

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			getProject();
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});

		
		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, routes, props.pageId]);

	const getProject = () => {
		api.get('/get-project/' + slug).then((res) => {
			setProject({...res.data, ...res.data[lang]});
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});
	}
	
	if (_.isEmpty(text)) { return null; }

	return (
		<main className="PAGE PROJECT">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={ lang } />
				<title>{ project.title }</title>
				<meta name="description" content={ project.about } />
			</Helmet>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95,
			}}>
				<PageBg>
					<section className="SECTION size2 no-border">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									{
										text.project?.preTitle &&
										<Scene classToggle="animationTriggered">
											<div className="SECTION__pretitle text-center elFadeUp delay-xs">
												<span>{ text.project?.preTitle }</span>
											</div>
										</Scene>
									}

									{
										text.project?.title &&
										<Scene classToggle="animationTriggered">
											<h1 className="SECTION__title text-center diff3 elFadeUp delay-sm" dangerouslySetInnerHTML={{ __html: text.project?.title }}></h1>
										</Scene>
									}

									{
										text.project?.subtitle &&
										<Scene classToggle="animationTriggered">
											<h2 className="SECTION__subtitle text-center elFadeUp delay-md" dangerouslySetInnerHTML={{ __html: text.project?.subtitle }}></h2>
										</Scene>
									}

									{
										project &&
										<div className="SECTION__content">
											<div className="SECTION__item">
												<ProjectItem
													innerPage={true}
													reversed={false}
													slug={project.slug}
													image={project.image}
													title={project.title}
													type={project.type}
													about={project.about}
													status={project.status}
													buttons={project.buttons}
												/>
											</div>
										</div>
									}
								</div>

								{
									project.details1?.length > 0 &&
									<div className="col-12 col-md-6">
										<div className="SECTION__item full-height">
											<div className="PROJECT__details">
												{
													_.map(project.details1, (val1, i1) => {
														if (val1.url) {
															return (
																<Scene key={i1} classToggle="animationTriggered">
																	<a href={val1.url} target="_blank" className="PROJECT__details-item elFadeUp" rel="noreferrer">{ val1.text }</a>
																</Scene>
															)
														}

														if (!val1.url) {
															return (
																<Scene key={i1} classToggle="animationTriggered">
																	<div className="PROJECT__details-item elFadeUp">{ val1.text }</div>
																</Scene>
															)
														}
													})
												}
											</div>
										</div>
									</div>
								}

								{
									project.details2?.length > 0 &&
									<div className="col-12 col-md-6">
										<div className="SECTION__item full-height">
											<div className="PROJECT__details">
												{
													_.map(project.details2, (val1, i1) => {
														if (val1.url) {
															return (
																<Scene key={i1} classToggle="animationTriggered">
																	<a href={val1.url} target="_blank" className="PROJECT__details-item elFadeUp" rel="noreferrer">{ val1.text }</a>
																</Scene>
															)
														}

														if (!val1.url) {
															return (
																<Scene key={i1} classToggle="animationTriggered">
																	<div className="PROJECT__details-item elFadeUp">{ val1.text }</div>
																</Scene>
															)
														}
													})
												}
											</div>
										</div>
									</div>
								}

					
								{
									project.gallery?.length > 0 &&
									<div className="col-12">
										<LightGallery
											speed={500}
											plugins={[lgZoom]}
											selector=".addToLightbox-item"
											mode="lg-fade"
										>
											<Swiper
												spaceBetween={10}
												slidesPerView={1}
												modules={[Scrollbar]}
												scrollbar={{
													'el': '.PROJECT__swiper-scrollbar',
													'draggable': true
												}}
												loop={false}
												className="PROJECT__slider"
												breakpoints={{
													768: {
														'slidesPerView': 2,
														'spaceBetween': 20
													},
													1024: {
														'slidesPerView': 3,
														'spaceBetween': 20
													}
												}}
											>
												{
													_.map(project.gallery, (val1: any, i1: number) => {
														let imageUrl: string = apiBasePath + '/uploads/' + (
															checkCookie('webpSupported') ? 
															val1.url.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : 
															val1.url
														);
														
														return (
															<SwiperSlide key={i1}>
																<div className="SECTION__item">
																	<Scene classToggle="animationTriggered">
																		{(progress: any, event: any) => (
																			<div className="PROJECT__slider-item elFadeUp">
																				<div data-src={imageUrl} className="PROJECT__slider-item-image addToLightbox-item">
																					<ProgressiveImg
																						img={false}
																						showLoader={true}
																						shouldLoad={event.type === 'start'}
																						lowQualitySrc={''}
																						highQualitySrc={imageUrl}
																					/>

																					<img src={imageUrl} alt={val1.alt} className="hidden" />
																				</div>
																			</div>
																		)}
																	</Scene>
																</div>
															</SwiperSlide>
														)
													})
												}

												<div className="PROJECT__swiper-scrollbar swiper-scrollbar">
													<div className="swiper-scrollbar-drag"></div>
												</div>
											</Swiper>
										</LightGallery>
									</div>
								}
							</div>
						</div>
					</section>
				</PageBg>
			</Controller>
		</main>
	);
}

export default Project;
