import React from 'react';
import _ from 'lodash';

// Import styles
import './popup.scss';

interface props {
	'title'?: string,
	'text'?: Array<any>,
	'active': boolean,
	'submitBtn'?: string,
	'onSubmit'?: any
	'closeBtn'?: string,
	'disabledSubmitBtn'?: boolean,
	'children'?: any,
	'onClose'?: any
};

const Popup = (props: props) => {
	if (!props.active) { return null; }
	
	return (
		<div className={'POPUP' + (props.active ? ' active' : '')}>
			<div className="POPUP__table">
				{
					props.onClose &&
					<div className="POPUP__closeoverlay" onClick={props.onClose}></div>
				}

				<div className="POPUP__table-cell">
					<div className="grid">
						<div className="row">
							<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
								<div className="POPUP__inner">
									{/*
										<div className="POPUP__bgs">
											<div className="POPUP__bgs-bg1" style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/bg/bg4.svg)'}}></div>
											<div className="POPUP__bgs-bg2" style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/bg/bg3.svg)'}}></div>
										</div>
									*/}

									{
										props.onClose &&
										<div className="POPUP__close">
											<div className="POPUP__close-item" onClick={props.onClose}>
												<div className="icon-plus-regular"></div>
											</div>
										</div>
									}

									<div className="POPUP__content">
										<div className="SECTION no-bottom-padding">
											<div className="grid">
												{
													props.title &&
													<div className="row">
														<div className="col-12">
															<div className="POPUP__title">
																{ props.title }
															</div>
														</div>
													</div>
												}
											</div>
										</div>

										{
											props.text &&
											<div className="SECTION no-bottom-padding">
												<div className="grid">
													<div className="row">
														<div className="col-12">
															{
																_.isArray(props.text) && !_.isEmpty(props.text) &&
																<div className="POPUP__messages">
																	{
																		_.map(props.text, (val1, i1) => {
																			if (/<("[^"]*"|'[^']*'|[^'">])*>/.test(val1)) {
																				return (
																					<div key={i1} dangerouslySetInnerHTML={{__html: val1}}></div>
																				)
																			} else {
																				return (
																					<p key={i1} dangerouslySetInnerHTML={{__html: val1}}></p>
																				)
																			}
																		})
																	}
																</div>
															}

															{
																!_.isArray(props.text) && !_.isEmpty(props.text) &&
																<div className="POPUP__messages" dangerouslySetInnerHTML={{__html: props.text}}></div>
															}
														</div>
													</div>
												</div>
											</div>
										}

										{ 
											props.children && 
											props.children
										}

										{
											(props.submitBtn || props.closeBtn) &&
											<div className="SECTION no-bottom-padding">
												<div className="grid">
													<div className="row">
														<div className="col-12">
															<div className="row">
																{
																	props.submitBtn &&
																	<div className="col-8">
																		<button onClick={props.onSubmit} className="BUTTON" disabled={props.disabledSubmitBtn}>
																			{ props.submitBtn }
																		</button>
																	</div>
																}

																{
																	props.closeBtn &&
																	<div className="col-4">
																		<span onClick={props.onClose} className="BUTTON diff1 AHREF">
																			{ props.closeBtn }
																		</span>
																	</div>
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Popup;