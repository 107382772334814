import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Controller, Scene } from 'react-scrollmagic';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { checkCookie } from './../../other/helpers/cookies';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import PageBg from './../../components/page-bg/page-bg';
import ProjectItem from './../../components/project-item/project-item';
import ContactsItem from './../../components/contacts-item/contacts-item';
import ProgressiveImg from './../../components/progressive-img/progressive-img';

import './about.scss';

const Parallax = require('parallax-js');

interface props {
	'pageId': string
}

function About(props: props) {
	const navigate = useNavigate();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});
	const [windowHeight, setWindowHeight] = useState<any>(0);
	const [projects, setProjects] = useState<Array<any>>([]);

	const sceneNode1 = useRef(null);
	const sceneNode2 = useRef(null);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			getProjects(1, 3);
			setWindowHeight(window.innerHeight);
		}).catch((err) => {			
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, routes, props.pageId]);

	useEffect(() => {
		let resizeTimer: any = false;

		window.addEventListener('resize', function(e) {
			clearTimeout(resizeTimer);
			
			resizeTimer = setTimeout(function() {
				let newWindowHeight: number = window.innerHeight;

				if (window.innerWidth > 992) {
					setWindowHeight(newWindowHeight);
				} 
				// else if (newWindowHeight > windowHeight + 200 || newWindowHeight < windowHeight - 200) {
				// 	setWindowHeight(newWindowHeight);
				// }
			}, 200);
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowHeight]);

	useEffect(() => {
		if (_.isEmpty(text) || !sceneNode1?.current) { return; }

		const parallaxInstance1 = new Parallax(sceneNode1?.current);

		parallaxInstance1.enable();

		return () => {
			parallaxInstance1.disable();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sceneNode1?.current, text]);

	useEffect(() => {
		if (_.isEmpty(text) || !sceneNode2?.current) { return; }

		const parallaxInstance2 = new Parallax(sceneNode2?.current);

		parallaxInstance2.enable();

		return () => {
			parallaxInstance2.disable();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sceneNode2?.current, text]);

	const getProjects = (page: number, limit: number) => {
		api.get('/get-projects/' + page + '/' + limit).then((res) => {
			setProjects(res.data.entries);
		});
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<main className="PAGE ABOUT">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang="en" />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={ text.pageDescription } />
			</Helmet>
			
			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageBg>
					<div className="SECTION size2 no-border ABOUT__intro" style={{ height: windowHeight }}>
						<div className="SECTION__bg">
							{
								(
									text.intro?.bg0 ||
									text.intro?.bg1 ||
									text.intro?.bg2
								) &&
								<div className="ABOUT__scene diff1" ref={sceneNode1}>
									{
										text.intro?.bg0 &&
										<div className="ABOUT__scene-item" data-depth="0.05">
											<div className="ABOUT__scene-item-image delay-md">
												<ProgressiveImg
													img={false}
													showLoader={true}
													shouldLoad={true}
													lowQualitySrc={''}
													highQualitySrc={process.env.PUBLIC_URL + '/' + (
														checkCookie('webpSupported') ? 
														text.intro?.bg0.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : 
														text.intro?.bg0
													)}
												/> 	
											</div>
										</div>
									}

									{
										text.intro?.bg1 &&
										<div className="ABOUT__scene-item" data-depth="0.1">
											<div className="ABOUT__scene-item-image delay-md" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + text.intro?.bg1 + ')' }}></div>
										</div>
									}

									{
										text.intro?.bg2 &&
										<div className="ABOUT__scene-item" data-depth="0.15">
											<div className="ABOUT__scene-item-image delay-xs" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + text.intro?.bg2 + ')' }}></div>
										</div>
									}
								</div>
							}
						</div>
						
						<div className="grid full-height">
							<div className="row full-height vertical-aligned">
								<div className="col-12 col-sm-8 push-sm-2">
									{
										text.intro?.el1 &&
										<div className="ABOUT__intro-image">	
											<div className="ABOUT__scene" ref={sceneNode2}>
												{
													text.intro?.el1 &&
													<div className="ABOUT__scene-item" data-depth="1">
														{
															process.env?.REACT_APP_ENV?.includes('azhuko') &&
															<div className="SECTION__item">
																<Scene classToggle="animationTriggered">
																	<div className="ABOUT__intro-image elFadeUp delay-xs">
																		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
																		width="1028px" height="1028px" viewBox="0 0 1028 1028" enableBackground="new 0 0 1028 1028" xmlSpace="preserve">
																		<defs>
																		<path id="SVGID_1_" d="M736.535,229.58H609.383l63.6,110.124L736.535,229.58z M418.627,229.58H291.478l63.587,110.124L418.627,229.58z
																		M275.586,238.753l-63.584,110.125h127.136L275.586,238.753z M418.613,504.929H291.464l63.588,110.125L418.613,504.929z
																		M357.344,514.104L293.76,624.229h127.136L357.344,514.104z M498.102,642.57h-127.15l63.588,110.123L498.102,642.57z
																		M355.061,651.744l-63.583,110.125h127.135L355.061,651.744z M339.138,367.25H211.989l63.588,110.125L339.138,367.25z
																		M275.586,376.424l-63.584,110.125h127.136L275.586,376.424z M434.554,238.753l-63.615,110.125h127.163L434.554,238.753z
																		M593.508,238.753l-63.602,110.125h127.15L593.508,238.753z M752.463,238.753l-63.607,110.125h127.156L752.463,238.753z
																		M736.535,504.929H609.383l63.6,110.125L736.535,504.929z M672.987,514.104l-63.604,110.125h127.152L672.987,514.104z
																		M657.061,642.605H529.906l63.602,110.123L657.061,642.605z M672.987,651.78l-63.604,110.124h127.152L672.987,651.78z
																		M816.012,367.286H688.855l63.603,110.124L816.012,367.286z M752.458,376.424l-63.604,110.125h127.152L752.458,376.424z
																		M657.057,367.227h-127.15l63.602,110.151L657.057,367.227z M498.102,367.227H370.939l63.615,110.151L498.102,367.227z
																		M514.028,376.424l-63.61,110.125h127.163L514.028,376.424z M339.151,504.957H211.989l63.615,110.151L339.151,504.957z
																		M816.006,504.957h-127.15l63.603,110.151L816.006,504.957z M275.586,0.126l-63.584,110.107h127.136L275.586,0.126z M752.463,0
																		l-63.607,110.106h127.156L752.463,0z M816.012,110.249H688.855l63.607,110.147L816.012,110.249z M339.125,110.233H211.989
																		l63.583,110.146L339.125,110.233z M355.052,119.439l-63.588,110.115h127.149L355.052,119.439z M672.982,119.456l-63.6,110.115
																		h127.152L672.982,119.456z M577.581,504.885H450.418l63.61,110.147L577.581,504.885z M434.554,514.082l-63.615,110.102h127.163
																		L434.554,514.082z M418.613,780.254H291.464l63.588,110.125L418.613,780.254z M434.554,789.406l-63.615,110.102h127.163
																		L434.554,789.406z M593.508,514.082l-63.602,110.102h127.15L593.508,514.082z M738.775,780.227H611.622l63.603,110.125
																		L738.775,780.227z M595.748,789.379l-63.6,110.104h127.148L595.748,789.379z M529.906,642.588l63.602,110.088l63.549-110.088
																		M514.028,651.731l-63.61,110.151h127.163L514.028,651.731z M577.581,780.227H450.418l63.61,110.146L577.581,780.227z
																		M657.057,917.871h-127.15L593.508,1028L657.057,917.871z M498.102,917.871H370.939L434.554,1028L498.102,917.871z M514.028,886.703
																		l-63.61,110.119h127.163L514.028,886.703z"/>
																		</defs>
																		<clipPath id="SVGID_2_">
																		<use xlinkHref="#SVGID_1_" overflow="visible"/>
																		</clipPath>
																		<image clipPath="url(#SVGID_2_)" x="0" y="0" width="100%" height="100%" xlinkHref={process.env.PUBLIC_URL + '/' + text.intro?.el1}></image>
																		</svg>
																	</div>
																</Scene>
															</div>
														}

														{
															process.env?.REACT_APP_ENV?.includes('azukaitis') &&
															<div className="SECTION__item">
																<Scene classToggle="animationTriggered">
																	<div className="ABOUT__intro-image elFadeUp delay-xs">
																		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
																		viewBox="0 0 512 512" xmlSpace="preserve">
																		<g>
																		<path id="SVGID_1_" d="M213.3,349.3v-17l10.3-13.6l41.2-54.4l-60.7-160.7h-87.7L0,409h87.7l17.4-51.5H213l0.3,0.9V349.3z
																		M127.7,290.6l32-94.8l31.2,94.8H127.7z"/>
																		<g>
																		<polygon id="SVGID_2_" points="509.2,103 264.4,103 264.4,173.8 395.9,173.8 263.4,348.8 263.4,408.4 512,408.4 512,337.6 
																		376.3,337.6 509.2,162.6 		"/>
																		</g>
																		</g>
																		<clipPath id="SVGID_3_">
																		<use xlinkHref="#SVGID_1_" overflow="visible"/>
																		</clipPath>
																		<clipPath id="SVGID_4_">
																		<use xlinkHref="#SVGID_2_" overflow="visible"/>
																		</clipPath>
																		<image clipPath="url(#SVGID_3_)" x="0" y="0" width="100%" height="100%" xlinkHref={process.env.PUBLIC_URL + '/' + text.intro?.el1}></image>
																		<image clipPath="url(#SVGID_4_)" x="0" y="0" width="100%" height="100%" xlinkHref={process.env.PUBLIC_URL + '/' + text.intro?.el1}></image>
																		</svg>
																	</div>
																</Scene>
															</div>
														}
													</div>
												}
											</div>
										</div>
									}

									{
										text.intro?.preTitle &&
										<Scene classToggle="animationTriggered">
											<div className="SECTION__pretitle text-center elFadeUp delay-xs">
												<span>{ text.intro?.preTitle }</span>
											</div>
										</Scene>
									}

									{
										text.intro?.title &&
										<Scene classToggle="animationTriggered">
											<h1 className="SECTION__title text-center diff3 elFadeUp delay-sm" dangerouslySetInnerHTML={{ __html: text.intro?.title }}></h1>
										</Scene>
									}

									{
										text.intro?.subtitle &&
										<Scene classToggle="animationTriggered">
											<h2 className="SECTION__subtitle diff2 text-center elFadeUp delay-md" dangerouslySetInnerHTML={{ __html: text.intro?.subtitle }}></h2>
										</Scene>
									}

									{
										text.intro?.buttons?.length > 0 &&
										<div className="SECTION__content">
											<div className="row">
												{
													_.map(text.intro?.buttons, (val1: any, i1: number) => {
														return (
															<div key={i1} className={val1.gridClasses}>
																<Scene classToggle="animationTriggered">
																	<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON elFadeUp delay-lg' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																</Scene>
															</div>
														)
													})	
												}
											</div>
										</div>
									}
								</div>
							</div>
						</div>

						<div className="ABOUT__intro-bottom">
							<div className="grid">
								<div className="row">
									<div className="col-12">
										<div className="ABOUT__intro-scroll">
											Scroll
										</div>

										<div className="ABOUT__intro-items">
											<div className="ABOUT__intro-links">
											</div>

											{
												text.intro?.links?.length > 0 &&
												<div className="ABOUT__intro-links">
													{
														_.map(text.intro?.links, (val1: any, i1: number) => {
															return (
																<a key={i1} href={val1.link} target="_blank" rel="noreferrer" className="ABOUT__intro-links-item">
																	<span className={'icon-' + val1.icon }></span>
																</a>
															)
														})
													}
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{
						_.map(text.about, (val1: any, i1: number) => {
							return (
								<section key={i1} className="SECTION size2 diff1 no-border">
									<div className="grid">
										<div className={'row vertical-aligned' + (val1.position === 'right' ? ' reversed-cols' : '')}>
											<div className={'col-12 col-md-6' + (val1.position === 'center' ? ' push-md-3 text-center' : '')}>
												{
													val1.image &&
													<div className="SECTION__image">
														<Scene classToggle="animationTriggered">
															<div className="SECTION__image-item elFadeUp delay-sm" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + val1.image + ')' }}></div>
														</Scene>
													</div>
												}
											</div>

											<div className={'col-12 col-md-6' + (val1.position === 'center' ? ' push-md-3 text-center' : '')}>
												{
													val1.preTitle &&
													<Scene classToggle="animationTriggered">
														<div className="SECTION__pretitle text-center elFadeUp delay-xs">
															<span>{ val1.preTitle }</span>
														</div>
													</Scene>
												}

												{
													val1.title &&
													<Scene classToggle="animationTriggered">
														<h3 className={'SECTION__title elFadeUp delay-sm' + (val1.position === 'center' ? ' centered' : '')}>
															{ val1.title }
														</h3>
													</Scene>
												}

												{
													val1.subtitle &&
													<Scene classToggle="animationTriggered">
														<h4 className={'SECTION__subtitle elFadeUp delay-md' + (val1.position === 'center' ? ' centered' : '')} dangerouslySetInnerHTML={{__html: val1.subtitle}}></h4>
													</Scene>
												}

												{
													val1.text &&
													<Scene classToggle="animationTriggered">
														<div className={'elFadeUp delay-md' + (val1.position === 'center' ? ' centered' : '')} dangerouslySetInnerHTML={{__html: val1.text}}></div>
													</Scene>
												}

												{
													val1.buttons?.length > 0 &&
													<div className="SECTION__content">
														<div className="row">
															{
																_.map(val1.buttons, (val2, i1) => {
																	return (
																		<div key={i1} className={val2.gridClasses}>
																			<Scene classToggle="animationTriggered">
																				<Link to={slugByPageId(val2.link, lang, routes)} className={'BUTTON elFadeUp delay-lg' + (val2.diff ? ' ' + val2.diff : '') + (val2.side === 'center' ? ' centered' : '')}>{ val2.text }</Link>
																			</Scene>
																		</div>
																	)
																})	
															}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</section>
							)
						})
					}
					
					{
						projects?.length > 0 &&
						<section className="SECTION size2 no-border">
							<div className="grid full-height">
								<div className="row full-height vertical-aligned">
									<div className="col-12 col-sm-8 push-sm-2">
										{
											text.projects?.preTitle &&
											<Scene classToggle="animationTriggered">
												<div className="SECTION__pretitle text-center elFadeUp delay-xs">
													<span>{ text.projects?.preTitle }</span>
												</div>
											</Scene>
										}

										{
											text.projects?.title &&
											<Scene classToggle="animationTriggered">
												<h3 className="SECTION__title text-center elFadeUp delay-sm" dangerouslySetInnerHTML={{ __html: text.projects?.title }}></h3>
											</Scene>
										}

										{
											text.projects?.subtitle &&
											<Scene classToggle="animationTriggered">
												<h4 className="SECTION__subtitle text-center elFadeUp delay-md" dangerouslySetInnerHTML={{ __html: text.projects?.subtitle }}></h4>
											</Scene>
										}
									</div>
								</div>

								<div className="row">
									<div className="col-12">
										<div className="SECTION__content">
											<Swiper
												spaceBetween={20}
												slidesPerView={1}
												modules={[Scrollbar]}
												scrollbar={{
													'el': '.ABOUT__swiper-scrollbar',
													'draggable': true
												}}
												loop={false}
												className="ABOUT__slider"
											>
												{
													_.map(projects, (val1: any, i1: number) => {
														val1 = {...val1, ...val1[lang]};

														return (
															<SwiperSlide key={i1}>
																<div className="SECTION__content">
																	<ProjectItem
																		innerPage={false}
																		reversed={false}
																		slug={val1.slug}
																		image={val1.image}
																		title={val1.title}
																		type={val1.type}
																		about={val1.about}
																		status={val1.status}
																		buttons={val1.buttons}
																	/>
																</div>
															</SwiperSlide>
														)
													})
												}

												<div className="ABOUT__swiper-scrollbar swiper-scrollbar">
													<div className="swiper-scrollbar-drag"></div>
												</div>
											</Swiper>
										</div>
										
										{
											text.projects?.buttons &&
											<div className="SECTION__content">
												<div className="row">
													{
														_.map(text.projects?.buttons, (val1: any, i1: number) => {
															return (
																<div key={i1} className={val1.gridClasses}>
																	<Scene classToggle="animationTriggered">
																		<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON elFadeUp delay-xs' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																	</Scene>
																</div>
															)
														})	
													}
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</section>
					}

					{
						text.stats &&
						<section className="SECTION size2 no-border">
							<div className="grid full-height">
								<div className="row full-height vertical-aligned">
									<div className="col-12 col-sm-8 push-sm-2">
										{
											text.stats?.preTitle &&
											<Scene classToggle="animationTriggered">
												<div className="SECTION__pretitle text-center elFadeUp delay-xs">
													<span>{ text.stats?.preTitle }</span>
												</div>
											</Scene>
										}

										{
											text.stats?.title &&
											<Scene classToggle="animationTriggered">
												<h3 className="SECTION__title text-center elFadeUp delay-sm" dangerouslySetInnerHTML={{ __html: text.stats?.title }}></h3>
											</Scene>
										}

										{
											text.stats?.subtitle &&
											<Scene classToggle="animationTriggered">
												<h4 className="SECTION__subtitle text-center elFadeUp delay-md" dangerouslySetInnerHTML={{ __html: text.stats?.subtitle }}></h4>
											</Scene>
										}

										{
											text.stats?.statItems?.length > 0 &&
											<div className="SECTION__content">
												<div className="row">
													{
														_.map(text.stats?.statItems, (val1: any, i1: number) => {
															return (
																<div key={i1} className="col-4">
																	<Scene classToggle="animationTriggered">
																		<div className="ABOUT__statitem text-center elFadeUp delay-lg">
																			<div className="SECTION__title diff3">{ val1.title }</div>
																			<div className="SECTION__subtitle diff3">{ val1.subtitle }</div>
																		</div>
																	</Scene>
																</div>
															)
														})
													}
												</div>
											</div>
										}

										{
											text.stats?.buttons?.length > 0 &&
											<div className="SECTION__content">
												<div className="row">
													{
														_.map(text.stats?.buttons, (val1: any, i1: number) => {
															return (
																<div key={i1} className={val1.gridClasses}>
																	<Scene classToggle="animationTriggered">
																		<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON elFadeUp delay-lg' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																	</Scene>
																</div>
															)
														})	
													}
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</section>
					}
					
					<ContactsItem />
				</PageBg>
			</Controller>
		</main>
	);
}

export default About;
