import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Scene } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Input from './../../components/input/input';
import Textarea from './../../components/textarea/textarea';

// Import styles
import './contacts-item.scss';

function ContactsItem() {	
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const initErrors: any = {
		'name': '',
		'email': '',
		'subject': '',
		'message': '',
		'general': ''
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': ''};
	const initForm: any = {
		'name': '',
		'email': '',
		'subject': '',
		'message': '',
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [form, setForm] = useState<{[key: string]: any}>(initForm);
	const [errors, setErrors] = useState<{[key: string]: any}>(initErrors);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);
		
	const onSendMessage =(e: any) => {
		e.preventDefault();

		setIsLoading(true);
		setErrors(initErrors);
		setNotifications(initNotifications);

		let stateClone: any = _.cloneDeep(form);

		// Validation
		let fieldErrors = validateFields(stateClone, ['contact']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors({...initErrors, ...fieldErrors, 'general': true});
			setNotifications({'title': 'Field error', 'text': [generalText?.contactsItem?.form?.fieldErrors]});

			return;
		}

		let formData: object = stateClone;

		api.post('/send-message', formData).then((res: any) => {
			
			setForm(initForm);
			setErrors(initForm);
			setNotifications(res.data?.messages);
			setIsLoading(false);

		}).catch((err: any) => {
			setErrors({...initErrors, 'general': true});
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		});
	}

	if (_.isEmpty(generalText?.contactsItem)) { return null; }

	return (
		<section className="SECTION size2 no-border CONTACTSITEM">
			<div className="grid">
				<div className={'row vertical-aligned' + (generalText?.contactsItem?.position === 'right' ? ' reversed-cols' : '')}>
					<div className={'col-12 col-md-6' + (generalText?.contactsItem?.position === 'center' ? ' push-md-3 text-center' : '')}>
						{
							generalText?.contactsItem?.preTitle &&
							<Scene classToggle="animationTriggered">
								<div className="SECTION__pretitle elFadeUp delay-xs">
									<span>{ generalText?.contactsItem?.preTitle }</span>
								</div>
							</Scene>
						}

						{
							generalText?.contactsItem?.title &&
							<Scene classToggle="animationTriggered">
								<h3 className={'SECTION__title elFadeUp delay-sm' + (generalText?.contactsItem?.position === 'center' ? ' centered' : '')}>
									{ generalText?.contactsItem?.title }
								</h3>
							</Scene>
						}

						{
							generalText?.contactsItem?.subtitle &&
							<Scene classToggle="animationTriggered">
								<h4 className={'SECTION__subtitle elFadeUp delay-md' + (generalText?.contactsItem?.position === 'center' ? ' centered' : '')} dangerouslySetInnerHTML={{__html: generalText?.contactsItem?.subtitle}}></h4>
							</Scene>
						}

						<div className="ABOUT__item-info">
							{
								generalText?.contactsItem?.text &&
								<Scene classToggle="animationTriggered">
									<div className={'elFadeUp delay-md' + (generalText?.contactsItem?.position === 'center' ? ' centered' : '')} dangerouslySetInnerHTML={{__html: generalText?.contactsItem?.text}}></div>
								</Scene>
							}

							{
								generalText?.contactsItem?.buttons &&
								<div className="SECTION__content">
									<div className="row">
										{
											_.map(generalText?.contactsItem?.buttons, (val2, i1) => {
												return (
													<div key={i1} className={val2.gridClasses}>
														<Scene classToggle="animationTriggered">
															<Link to={slugByPageId(val2.link, lang, routes)} className={'BUTTON elFadeUp delay-lg' + (val2.diff ? ' ' + val2.diff : '') + (val2.side === 'center' ? ' centered' : '')}>{ val2.text }</Link>
														</Scene>
													</div>
												)
											})	
										}
									</div>
								</div>
							}
						</div>
					</div>
					
					<div className={'col-12 col-md-6' + (generalText?.contactsItem?.position === 'center' ? ' push-md-3' : '')}>
						<div className="SECTION__content">
							{
								generalText?.contactsItem?.form &&
								<Scene classToggle="animationTriggered">
									<form onSubmit={(e) => onSendMessage(e)} className="FORM elFadeUp delay-xs">
										<div className="row">
											<div className="col-6">
												<div className={'FIELD' + (errors.name ? ' error' : '')}>
													<label>
														{ generalText?.contactsItem?.form?.input1?.label }
													</label>

													<Input
														value={form.name}
														type="text"
														placeholder={generalText?.contactsItem?.form?.input1?.placeholder}
														onChange={(val: string) => setForm({
															...form, 'name': val
														})}
													/>

													{/*{
														errors.name &&
														<p className="error">
															{errors.name}
														</p>
													}*/}
												</div>
											</div>

											<div className="col-6">
												<div className={'FIELD' + (errors.email ? ' error' : '')}>
													<label>
														{ generalText?.contactsItem?.form?.input2?.label }
													</label>

													<Input
														value={form.email}
														type="text"
														placeholder={generalText?.contactsItem?.form?.input2?.placeholder}
														onChange={(val: string) => setForm({
															...form, 'email': val
														})}
													/>

													{/*{
														errors.email &&
														<p className="error">
															{errors.email}
														</p>
													}*/}
												</div>
											</div>

											<div className="col-12">
												<div className={'FIELD' + (errors.subject ? ' error' : '')}>
													<label>
														{ generalText?.contactsItem?.form?.input3?.label }
													</label>

													<Input
														value={form.subject}
														type="text"
														placeholder={generalText?.contactsItem?.form?.input3?.placeholder}
														onChange={(val: string) => setForm({
															...form, 'subject': val
														})}
													/>

													{/*{
														errors.subject &&
														<p className="error">
															{errors.subject}
														</p>
													}*/}
												</div>
											</div>

											<div className="col-12">
												<div className={'FIELD' + (errors.message ? ' error' : '')}>
													<label>
														{ generalText?.contactsItem?.form?.textarea1?.label }
													</label>

													<Textarea
														value={form.message}
														placeholder={generalText?.contactsItem?.form?.textarea1?.placeholder}
														onChange={(val: string) => setForm({
															...form, 'message': val
														})}
													/>

													{/*{
														errors.message &&
														<p className="error">
															{errors.message}
														</p>
													}*/}
												</div>
											</div>

											{
												!_.isEmpty(notifications.text) &&
												<div className="col-12">
													<div className={'FORM__message' + (errors.general ? ' error' : ' success')}>
														{
															_.map(notifications.text, (val1, i1) => {
																return (
																	<p key={i1}>{ val1 }</p>
																)
															})
														}
													</div>
												</div>
											}

											<div className="col-12">
												<div className="SECTION__content">
													<div className="row">
														<div className={generalText?.contactsItem?.form?.buttons?.[0]?.gridClasses}>
															<button type="submit" name="contacts-form" className="BUTTON" disabled={isLoading}>
																{
																	isLoading ? 
																	generalText?.contactsItem?.form?.buttons?.[0]?.loading : 
																	generalText?.contactsItem?.form?.buttons?.[0]?.text
																}
															</button>
														</div>
													</div>	
												</div>
											</div>
										</div>
									</form>
								</Scene>
							}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ContactsItem;