import React from 'react';
import { useSelector } from 'react-redux';

import './resolution.scss';

interface props {}

function Resolution(props: props) {
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	return (
		<div className="RESOLUTION">
			<div className="RESOLUTION__inner">
				{
					generalText?.resolution?.text &&
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="RESOLUTION__inner-text">
									{ generalText?.resolution?.text }
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default Resolution;