import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import _ from 'lodash';

// Import middlewares
import api from './other/middlewares/api';

// Import actions
import { setLang } from './other/actions/lang';
import { setGeneralText } from './other/actions/general-text';

// Import components
import Header from './components/header/header';
// import Consent from './components/consent/consent';
// import ChatWidget from './components/chat-widget/chat-widget';
import Resolution from './components/resolution/resolution';
import Footer from './components/footer/footer';

import './main-layout.scss';

interface props {
	'lang': string
}

function MainLayout(props: props) {
	const dispatch = useDispatch();
	
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const [scrollPositionClass, setScrollPositionClass] = useState<string>('');

	const mainLayoutNode = useRef(null as null | HTMLDivElement);

	if (lang !== props.lang) {
		dispatch(setLang(props.lang));
	}

	useEffect(() => {
		api.get('/get-page/general').then((res) => {
			dispatch(setGeneralText({'generalText': res.data.text[props.lang]}))
		});
	}, [props.lang, dispatch]);

	useEffect(() => {
		window.addEventListener('scroll', function() {
			let scrollTrigger = window.pageYOffset + window.innerHeight / 2;

			let clientHeight: number = mainLayoutNode.current ? mainLayoutNode.current.clientHeight : 0; 

			if (
				window.pageYOffset < 10
			) {
				setScrollPositionClass('');
			} else if (
				window.pageYOffset > 10 &&
				scrollTrigger < clientHeight / 3 - 1 &&
				scrollPositionClass !== 'zero-scrolled'
			) {
				setScrollPositionClass('zero-scrolled');
			} else if (
				scrollTrigger > clientHeight / 3 && 
				scrollTrigger < clientHeight / 1.5 - 1 && 
				scrollPositionClass !== 'onethird-scrolled'
			) {
				setScrollPositionClass('onethird-scrolled');
			} else if (
				scrollTrigger > clientHeight / 1.5
			) {
				setScrollPositionClass('twothirds-scrolled');
			}

		});
	}, [scrollPositionClass]);

	useEffect(() => {
		document.body.classList.toggle('dark-theme',  (generalText?.isDarkThemeEnabled ? true : false));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[generalText?.isDarkThemeEnabled]);

	if (!generalText || _.isEmpty(generalText)) { return null; }

	return (
		<div className={'MAINLAYOUT' + (scrollPositionClass && ' ' + scrollPositionClass)} ref={mainLayoutNode}>
			<div className="SIDE">
				<div className="SIDE__progress">
					<div className="SIDE__progress-item"></div>
					<div className="SIDE__progress-item"></div>
					<div className="SIDE__progress-item"></div>
				</div>
			</div>

			<Header />
			<Outlet />
			{/*<Consent />*/}
			{/*<ChatWidget />*/}
			<Resolution />
			<Footer />
		</div>
	);
}

export default MainLayout;
