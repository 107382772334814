import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import styles
import './footer.scss';

interface props {};

const Footer = (props: props) => {
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	return (
		<footer className="SECTION size2 FOOTER no-border">
			<div className="grid">
				<div className="row">
					<div className="col-12">
						<div className="FOOTER__logo">
							<NavLink 
								to={slugByPageId(generalText?.footer?.logo?.link, lang, routes)}
								className="FOOTER__logo-link"
							>
								{
									generalText.footer?.logo?.image &&
									<img src={process.env.PUBLIC_URL + process.env.PUBLIC_URL + '/' + generalText?.footer?.logo?.image} className="logo" alt={generalText?.footer?.logo?.alt} />
								}

								{
									generalText.footer?.logo?.text1 &&
									<span className="text" dangerouslySetInnerHTML={{ __html: generalText.footer?.logo?.text1 }}></span>
								}
								
								{
									generalText.footer?.logo?.badge &&
									<span className="badge">{ generalText.footer?.logo?.badge }</span>
								}
							</NavLink>	
						</div>

						{
							generalText.footer?.text1 &&
							<div className="FOOTER__item" dangerouslySetInnerHTML={{ __html: generalText.footer?.text1 }}></div>
						}
					</div>

					<div className="col-12">

						<div className="FOOTER__item">
							<div className="FOOTER__item-links">
								{
									_.map(generalText.footer?.links, (val1, i1) => {
										return (
											<div key={i1} className="FOOTER__item-link">
												{
													val1.link &&
													<Link to={slugByPageId(val1.link, lang, routes)} className="LINK">{ val1.text }</Link>
												}

												{
													val1.url &&
													<a href={val1.url} className="LINK">{ val1.text }</a>
												}
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;