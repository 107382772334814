// import _ from 'lodash';

export function validateFields(fields: any, types: any) {
	let errors: any = {};

	if (types.includes('contact')) {
		if (fields.hasOwnProperty('name')) {
			let fieldValue = fields.name;

			// declineSymbols('name', fieldValue, 'Profile name');
			// declineSymbolsExceptLatinExtended('name', fieldValue, 'Profile name');
			maxCharLength('name', fieldValue, 100, 'Your name');
			minCharLength('name', fieldValue, 2, 'Your name');
			isEmpty('name', fieldValue, 'Your name');

			fields.name = fieldValue;
		}

		if (fields.hasOwnProperty('email')) {
			let fieldValue = fields.email;

			validateEmail('email', fieldValue, 'Your email');
			maxCharLength('email', fieldValue, 200, 'Your email');
			minCharLength('email', fieldValue, 2, 'Your email');
			isEmpty('email', fieldValue, 'Your email');

			fields.email = fieldValue.toLowerCase();
		}

		if (fields.hasOwnProperty('phone') && fields.phone !== '') {
			let fieldValue = fields.phone;

			validatePhone('phone', fieldValue, 'Your phone');
			maxCharLength('phone', fieldValue, 15, 'Your phone');
			minCharLength('phone', fieldValue, 2, 'Your phone');
			isEmpty('phone', fieldValue, 'Your phone');

			fieldValue = truncateSpaces(fieldValue);
			fields.phone = fieldValue;
		}

		if (fields.hasOwnProperty('subject')) {
			let fieldValue = fields.subject;

			maxCharLength('subject', fieldValue, 100, 'Your subject');
			minCharLength('subject', fieldValue, 2, 'Your subject');
			isEmpty('subject', fieldValue, 'Your subject');

			fields.subject = fieldValue;
		}
		

		if (fields.hasOwnProperty('message')) {
			let fieldValue = fields.message;

			maxCharLength('message', fieldValue, 3000, 'Your message');
			declineUrl('message', fieldValue);
			minCharLength('message', fieldValue, 1, 'Your message');
			isEmpty('message', fieldValue, 'Your message');
			
			// fieldValue = truncateNewLines(fieldValue);
			// fieldValue = capitalizeFirstLetter(fieldValue);
			fieldValue = truncateLeadingAndEndingSpaces(fieldValue);
			fieldValue = truncateDoubleSpaces(fieldValue);
			fieldValue = truncateExtraNewLines(fieldValue);

			fields.message = fieldValue;
		}
	}

	// Validation functions
	// function capitalizeFirstLetter(fieldValue: string) {
	// 	return fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
	// }

	// function capitalizeFirstLetters(fieldValue: string) {
	// 	let splitStr = fieldValue.toLowerCase().split(' ');
		
	// 	for (let i = 0; i < splitStr.length; i++) {
	// 		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
	// 	}

	// 	return splitStr.join(' ');
	// }

	// function truncateNewLines(fieldValue: string) {
	// 	return fieldValue.replace(/\r?\n|\r/g, ' ');
	// }

	function truncateExtraNewLines(fieldValue: string) {
		return fieldValue.replace(/[\r\n]{3,}/g, '\n\n');
	}

	function truncateLeadingAndEndingSpaces(fieldValue: string) {
		return fieldValue.replace(/^[ \t]+|[ \t]+$/g, '');
	}

	function truncateDoubleSpaces(fieldValue: string) {
		return fieldValue.replace(/[ ]{2,}/g, ' ');
	}

	function truncateSpaces(fieldValue: string) {
		return fieldValue.replace(/\s/g, '');
	}

	function validateEmail(fieldName: string, fieldValue: string, field: string) {
		const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!regex.test(String(fieldValue).toLowerCase())) {
			errors[fieldName] = 'You have entered an invalid ' + field.toLowerCase() + ' address. Please use a valid ' + field.toLowerCase() + ' address.';
		}
	}

	function validatePhone(fieldName: string, fieldValue: string, field: string) {
		const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

		if (!regex.test(String(fieldValue).toLowerCase())) {
			errors[fieldName] = 'You have entered an invalid ' + field.toLowerCase() + ' number. Please enter a valid ' + field.toLowerCase() + ' number.';
		}
	}

	function isEmpty(fieldName: string, fieldValue: any, field: string) {
		if (!fieldValue || fieldValue?.length === 0) {
			errors[fieldName] = field + ' field was left empty.';
		}
	}

	// function isAccepted(fieldName: string, fieldValue: any, field: string) {
	// 	if (!fieldValue) {
	// 		errors[fieldName] = 'Please read and accept with our ' + field + ' in order to continue.';
	// 	}
	// }

	function minCharLength(fieldName: string, fieldValue: any, minChars: number, field: string) {
		if (fieldValue?.length < minChars) {
			errors[fieldName] = field + ' cannot have less than ' + minChars + ' characters.';
		}
	}

	function maxCharLength(fieldName: string, fieldValue: any, maxChars: number, field: string) {
		if (fieldValue?.length > maxChars) {
			errors[fieldName] = field + ' cannot have more than ' + maxChars + ' characters.';
		}
	}

	// function minLength(fieldName: string, fieldValue: any, minLength: number, field: string) {
	// 	if (fieldValue?.length < minLength) {
	// 		errors[fieldName] = 'Please add more than ' + minLength + ' ' + field + '.';
	// 	}
	// }

	// function maxLength(fieldName: string, fieldValue: any, maxLength: number, field: string) {
	// 	if (fieldValue?.length > maxLength) {
	// 		errors[fieldName] = 'Please add less than ' + maxLength + ' ' + field + '.';
	// 	}
	// }

	// function isNumeric(fieldName: string, fieldValue: any, field: string) {
	// 	if (!Number.isInteger(fieldValue)) {
	// 		errors[fieldName] = field + ' has to have a numeric value.';
	// 	}
	// }

	// function declineSymbolsExceptLatinExtended(fieldName: string, fieldValue: any, field: string) {
	// 	const regex = /(?![a-zA-Z])[!-~]+/;

	// 	if (regex.test(fieldValue)) {
	// 		errors[fieldName] = field + ' cannot contain any symbols, please remove them.';
	// 	}
	// }

	// function declineSymbols(fieldName: string, fieldValue: any, field: string) {
	// 	const regex = /[^a-zA-Z ]/;

	// 	if (regex.test(fieldValue)) {
	// 		errors[fieldName] = field + ' cannot contain any symbols, please remove them.';
	// 	}
	// }

	// function declineAllExceptDotsAndDashes(fieldName: string, fieldValue: any, field: string) {
	// 	const regex = /^[^a-zA-Z0-9]|[^\w.-]+|[^a-zA-Z0-9]$/;

	// 	if (regex.test(fieldValue)) {
	// 		errors[fieldName] = field + ' can only contain letters, numbers, hyphens, underscores and periods. Also please remove any symbols from the front and/or back if there is any.';
	// 	}
	// }

	// function declineSpaces(fieldName: string, fieldValue: any, field: string) {
	// 	const regex = /\s/;

	// 	if (regex.test(fieldValue)) {
	// 		errors[fieldName] = field + ' cannot contain any empty spaces, please remove them.';
	// 	}
	// }

	function declineUrl(fieldName: string, fieldValue: any) {
		let regex = /(https?:\/\/)|(www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

		if (regex.test(fieldValue)) {
			errors[fieldName] = '"' + fieldValue + '" contains a link to external website. Please remove this URL from the text.';
		}
	}

	// function validateUrl(fieldName: string, fieldValue: any) {
	// 	let regex = /(https?:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/;

	// 	if (!regex.test(fieldValue)) {
	// 		errors[fieldName] = 'Please enter a valid website URL.';
	// 	}
	// }

	// function validateSocialNetwork(fieldName: string, network: any, url: any) {
	// 	let availableNetworks: Array<any> = [
	// 		{ 'key': 'website', 'domain': '', 'fullDomain': 'https://', 'name': 'Website' },
	// 		{ 'key': 'facebook', 'domain': 'facebook.com', 'fullDomain': 'https://www.facebook.com', 'name': 'Facebook' },
	// 		{ 'key': 'instagram', 'domain': 'instagram.com', 'fullDomain': 'https://www.instagram.com', 'name': 'Instagram' },
	// 		{ 'key': 'twitter', 'domain': 'twitter.com', 'fullDomain': 'https://twitter.com', 'name': 'Twitter' },
	// 		{ 'key': 'linkedin', 'domain': 'linkedin.com', 'fullDomain': 'https://www.linkedin.com', 'name': 'LinkedIn' },
	// 		{ 'key': 'youtube', 'domain': 'youtube.com', 'fullDomain': 'https://www.youtube.com', 'name': 'YouTube' },
	// 		{ 'key': 'vimeo', 'domain': 'vimeo.com', 'fullDomain': 'https://vimeo.com', 'name': 'Vimeo' },
	// 		{ 'key': 'reddit', 'domain': 'reddit.com', 'fullDomain': 'https://www.reddit.com', 'name': 'Reddit' },
	// 		{ 'key': 'blogger', 'domain': 'blogger.com', 'fullDomain': 'https://www.blogger.com', 'name': 'Blogger' },
	// 		{ 'key': 'pinterest', 'domain': 'pinterest.com', 'fullDomain': 'https://www.pinterest.com', 'name': 'Pinterest' },
	// 		{ 'key': 'spotify', 'domain': 'spotify.com', 'fullDomain': 'https://open.spotify.com', 'name': 'Spotify' },
	// 		{ 'key': 'soundcloud', 'domain': 'soundcloud.com', 'fullDomain': 'https://soundcloud.com', 'name': 'SoundCloud' },
	// 		{ 'key': 'github', 'domain': 'github.com', 'fullDomain': 'https://github.com', 'name': 'GitHub' },
	// 		{ 'key': 'behance', 'domain': 'behance.net', 'fullDomain': 'https://www.behance.net', 'name': 'Behance' },
	// 		{ 'key': 'flickr', 'domain': 'flickr.com', 'fullDomain': 'https://www.flickr.com', 'name': 'Flickr' },
	// 		{ 'key': 'dribbble', 'domain': 'dribbble.com', 'fullDomain': 'https://dribbble.com', 'name': 'Dribbble' },
	// 		{ 'key': 'tumblr', 'domain': 'tumblr.com', 'fullDomain': 'https://www.tumblr.com', 'name': 'Tumblr' },
	// 		{ 'key': 'foursquare', 'domain': 'foursquare.com', 'fullDomain': 'https://foursquare.com', 'name': 'Foursquare' },
	// 		{ 'key': 'tiktok', 'domain': 'tiktok.com', 'fullDomain': 'https://www.tiktok.com', 'name': 'TikTok' },
	// 		{ 'key': 'fiverr', 'domain': 'fiverr.com', 'fullDomain': 'https://www.fiverr.com', 'name': 'Fiverr' },
	// 		{ 'key': 'upwork', 'domain': 'upwork.com', 'fullDomain': 'https://www.upwork.com', 'name': 'Upwork' },
	// 		{ 'key': 'freelancer', 'domain': 'freelancer.com', 'fullDomain': 'https://www.freelancer.com', 'name': 'Freelancer' },
	// 	];

	// 	// Check if network exists
	// 	if (_.find(availableNetworks, {'key': network})) {
	// 		let networkObj: { [key: string]: any } = _.find(availableNetworks, {'key' : network});

	// 		// Regex if string contains key between http slashes and .com
	// 		let regex = new RegExp('((http(s)?(://))+(www.)?([a-z0-9]+[.])?(' + networkObj.domain + '/))[^sbn|]*[^.,;:?!@^$ -]');

	// 		if (networkObj.domain && !regex.test(url)) {
	// 			errors[fieldName] = 'Your ' + networkObj.name + ' profile URL does not match with this example - ' + networkObj.fullDomain + '/yourExternalUsername.';
	// 		}
	// 	}
	// }

	// function validateEmbeddedURL(fieldName: string, network: any, url: any) {
	// 	let availableNetworks: Array<any> = [
	// 		{ 'key': 'youtube', 'domain': 'youtu.be', 'fullDomain': 'https://youtu.be/', 'name': 'YouTube' },
	// 		{ 'key': 'youtube', 'domain': 'youtube.com/watch', 'fullDomain': 'https://youtube.com/watch?', 'name': 'YouTube' },
	// 	];

	// 	let isMatch: boolean = false;
	// 	let closestMatch: any = {};

	// 	_.forEach(availableNetworks, (val1: any, i1: number) => {
	// 		if (val1.key === network) {
	// 			// Regex if string contains key between http slashes and .com
	// 			let regex = new RegExp('((http(s)?(://))+(www.)?([a-z0-9]+[.])?(' + val1.domain + '))[^sbn|]*[^.,;:?!@^$ -]');

	// 			closestMatch = val1;

	// 			if (regex.test(url)) {
	// 				isMatch = true;
	// 			}
	// 		}
	// 	});

	// 	if (!isMatch) {
	// 		errors[fieldName] = 'Your ' + closestMatch.name + ' emebedded URL does not match with this example - ' + closestMatch.fullDomain + 'videoID.';
	// 	}
	// }

	return errors;
}