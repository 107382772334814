import { STATE_RESET, SET_GENERAL_TEXT } from './../actions/types';

const initialState: object = {};

let updatedState = (state: object = initialState, action: {[key: string]: any} = {}) => {
	let generalTextData: object = action['generalText'] ?? initialState;

	switch(action['type']) {
		case STATE_RESET: return {...initialState};

		case SET_GENERAL_TEXT: return generalTextData;
		
		default: return state;
	}
}

export default updatedState;