import React from 'react';
import { useSelector } from 'react-redux';

import './page-bg.scss'

function PageBg(props: any) {
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	
	return (
		<div className="PAGEBG">
			{
				generalText?.pageBg?.bg1 &&
				<div className="PAGEBG__bg1" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/' + generalText?.pageBg?.bg1 + ')' }}></div>
			}

			{
				generalText?.pageBg?.bg2 &&
				<div className="PAGEBG__bg2" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/' + generalText?.pageBg?.bg2 + ')' }}></div>
			}

			{
				generalText?.pageBg?.bg3 &&
				<div className="PAGEBG__bg3" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/' + generalText?.pageBg?.bg3 + ')' }}></div>
			}

			{ props.children }
		</div>
	);
}

export default PageBg;
