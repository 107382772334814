export const basePath = 
	(process.env?.REACT_APP_ENV?.includes('development')) ? 
	'http://localhost:3000' : 
	(
		(process.env?.REACT_APP_ENV?.includes('azukaitis')) ? 'https://azukaitis.com' : 'https://azhuko.com'
	);
export const apiBasePath = 
	(process.env?.REACT_APP_ENV?.includes('development')) ? 
	'http://localhost:8080' : 
	(
		(process.env?.REACT_APP_ENV?.includes('azukaitis')) ? 'https://api.azukaitis.com' : 'https://api.azhuko.com'
	);