import React, { useEffect } from 'react';
import _ from 'lodash';

// Import styles
import './pagination.scss';

interface props {
	'currentPage': number,
	'totalPages': number,
	'prevText': string,
	'nextText': string,
	'onChangePage': any,
}

function Pagination(props: props) {
	let pages: any = [];

	if (props.totalPages >= 0) {
		for (let i = 0; i < props.totalPages; i++) {
			pages.push(i + 1);
		}
	}

	useEffect(() =>{
		window.scroll(0, 0);
	}, [props.onChangePage]);

	return (
		<div className="PAGINATION">
			{
				props.currentPage > 1 &&
				<div className="PAGINATION__prev" onClick={() => props.onChangePage(props.currentPage - 1)}>{ props.prevText }</div>
			}

			<div className="PAGINATION__items">
				{
					_.map(pages, (val1: any, i1: number) => {
						if (i1 === 0) {
							return (
								<span key={i1}>
									<span onClick={() => props.onChangePage(val1)} className={'PAGINATION__items-page' + (props.currentPage === val1 ? ' active' : '')}>{ val1 }</span>
									{
										props.currentPage > 4 &&
										<span className="PAGINATION__items-divider"> ... </span>
									}
								</span>
							)
						}

						if (i1 >= (props.currentPage - 3) && i1 <= (props.currentPage + 1)) {
							return (
								<span key={i1} onClick={() => props.onChangePage(val1)} className={'PAGINATION__items-page' + (props.currentPage === val1 ? ' active' : '')}>{ val1 }</span>
							)
						}


						if (i1 === props.totalPages) {
							return (
								<span key={i1}>
									{
										props.currentPage < (pages.length - 3) &&
										<span className="PAGINATION__items-divider"> ... </span>
									}
									<span onClick={() => props.onChangePage(val1)} className={'PAGINATION__items-page' + (props.currentPage === val1 ? ' active' : '')}>{ val1 }</span>
								</span>
							)
						}
					})
				}
			</div>

			{
				props.currentPage < props.totalPages &&
				<div className="PAGINATION__next" onClick={() => props.onChangePage(props.currentPage + 1)}>{ props.nextText }</div>
			}
		</div>
	);
}

export default Pagination;