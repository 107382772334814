import _ from 'lodash';

export function slugByPageId(pageId: string, lang: string, routes: {[key: string]: any}) {
	let slug: string = '/';

	if (lang && !_.isEmpty(routes)) {
		slug = '/' + (lang !== 'en' ? lang + '/' : '') + (_.find(routes[lang], {'pageId': pageId})?.slug);
	}

	return slug;
}