import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Controller } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import PageBg from './../../components/page-bg/page-bg';
import ContactsItem from './../../components/contacts-item/contacts-item';

import './contacts.scss';

interface props {
	'pageId': string
}

function Contacts(props: props) {
	const navigate = useNavigate();
	
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, routes, props.pageId]);
		
	if (_.isEmpty(text)) { return null; }

	return (
		<main className="PAGE CONTACTS">	
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={ lang } />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={ text.pageDescription } />
			</Helmet>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageBg>
					<ContactsItem />
				</PageBg>
			</Controller>
		</main>
	);
}

export default Contacts;