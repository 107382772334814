import React, { useState, useEffect } from 'react';

// Import components
import Loader from './../loader/loader';

import './progressive-img.scss';

interface props {
	'img': boolean,
	'noAnimation'?: boolean,
	'alt'?: string,
	'showLoader'?: boolean,
	'shouldLoad': boolean,
	'lowQualitySrc': string,
	'highQualitySrc': string,
	'children'?: any
}

function ProgressiveImg(props: props) {
	const [src, setSrc] = useState(props.lowQualitySrc);

	useEffect(() => {
		if (!props.shouldLoad) {
			return;
		}

		let isUnmounted: boolean = false;
		const img: any = new Image();
		img.src = props.highQualitySrc;
		
		img.onload = () => {
			if (isUnmounted) { return; }
			setSrc(props.highQualitySrc);
		};
		
		return () => {
			isUnmounted = true;
		};
	}, [props.lowQualitySrc, props.highQualitySrc, props.shouldLoad]);
	
	return (
		<span className={'PROGRESSIVEIMG' + (src === props.lowQualitySrc ? '' : ' active') + (props.noAnimation ? ' no-animation' : '')}>
			{
				props.showLoader &&
				<Loader
					fixed={false}
					active={props.highQualitySrc !== '' && src === props.lowQualitySrc}
					noBg={true}
				/>
			}

			{
				props.img &&
				<img src={src} alt={props.alt} className="PROGRESSIVEIMG__item img" />
			}

			{
				!props.img &&
				<span style={{ backgroundImage: 'url(' + src + ')' }} className="PROGRESSIVEIMG__item bg">
					{ props.children }
				</span>
			}
		</span>
	)
};

export default ProgressiveImg;