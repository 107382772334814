import { SET_LANG, STATE_RESET } from './../actions/types';

export function setLang(data: string) {
	return {
		'type': SET_LANG,
		'lang': data
	};
}

export function resetLang() {
	return {
		'type': STATE_RESET
	};
}