import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Scene } from 'react-scrollmagic';
import _ from 'lodash';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import helpers
import { checkCookie } from './../../other/helpers/cookies';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import ProgressiveImg from './../../components/progressive-img/progressive-img';
import Popup from './../../components/popup/popup';

import './project-item.scss';

interface props {
	'innerPage'?: boolean,
	'reversed': boolean,
	'slug'?: string,
	'image': {
		[key: string]: any
	},
	'title': string,
	'type': string,
	'about': string,
	'status': string,
	'buttons': Array<any>
}

function ProjectItem(props: props) {
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const initPopups: {[key: string]: any} = {
		'showExplicitContentPopup': ''
	};

	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);

	let previewImage: any = apiBasePath + '/uploads/' + (
		checkCookie('webpSupported') ? 
		props.image?.url.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : 
		props.image?.url
	);

	const onViewUrl = (url: string) => {
		window.open(url, '_blank');
	}

	return (
		<>
			<Popup
				title={'Warning: X-rated content'}
				text={['This is an X-rated website that contains explicit content. By proceeding you are agreeing that you are over the age of 18 years old.']}
				closeBtn={'Close'}
				submitBtn={'Proceed'}
				onSubmit={() => onViewUrl(popups.showExplicitContentPopup)}
				active={popups.showExplicitContentPopup !== ''}
				onClose={() => setPopups(initPopups)}
			/>

			<div className={'PROJECTITEM' + (!props.innerPage ? ' project-navigation-bullet': '')}>
				<div className={'row' + (props.reversed ? ' reversed-cols' : '')}>
					<div className="col-12 col-md-6">
						<Scene classToggle="animationTriggered">
							{(progress: any, event: any) => (
								<div className="PROJECTITEM__preview customAnimation">
									{
										props.innerPage &&
										props.buttons?.[0]?.url &&
										<a href={ props.buttons?.[0]?.url } target="_blank" className="PROJECTITEM__preview-link" rel="noreferrer"> </a>
									}

									{
										props.slug && 
										!props.innerPage &&
										<Link to={ slugByPageId('project', lang, routes).replace(':slug', '') + props.slug } className="PROJECTITEM__preview-link"></Link>
									}
									
									<div className="PROJECTITEM__preview-image">
										<ProgressiveImg
											img={false}
											showLoader={true}
											shouldLoad={event.type === 'start'}
											lowQualitySrc={''}
											highQualitySrc={previewImage}
										/>
									</div>

									{
										props.status &&
										<div className={'PROJECTITEM__preview-status ' + props.status.toLowerCase()}>
											{ props.status }
										</div>
									}

									<div className="PROJECTITEM__preview-cover"></div>
								</div>
							)}
						</Scene>
					</div>

					<div className="col-12 col-md-6">
						<div className="PROJECTITEM__body">
							{
								props.title &&
								props.innerPage &&
								<Scene classToggle="animationTriggered">
									<h1 className="SECTION__title diff2 elFadeRight delay-sm">
										{ props.title }
									</h1>
								</Scene>
							}

							{
								props.title &&
								!props.innerPage &&
								<Scene classToggle="animationTriggered">
									<h2 className="SECTION__title diff2 elFadeRight delay-sm">
										{ props.title }
									</h2>
								</Scene>
							}

							{
								props.type &&
								props.innerPage &&
								<Scene classToggle="animationTriggered">
									<h1 className="SECTION__subtitle elFadeRight delay-md">
										{ props.type }
									</h1>
								</Scene>
							}

							{
								props.type &&
								!props.innerPage &&
								<Scene classToggle="animationTriggered">
									<h2 className="SECTION__subtitle elFadeRight delay-md">
										{ props.type }
									</h2>
								</Scene>
							}

							{
								props.about &&
								<Scene classToggle="animationTriggered">
									<p className="elFadeRight delay-lg">{ props.about }</p>
								</Scene>
							}

							<div className="SECTION__content">
								{
									!props.innerPage &&
									<div className="row">
										{
											props.slug && 
											generalText?.projectItem?.button1?.text &&
											<div className={generalText?.projectItem?.button1?.gridClasses}>
												<Scene classToggle="animationTriggered">
													<Link to={ slugByPageId(generalText?.projectItem?.button1?.link, lang, routes).replace(':slug', '') + props.slug } className="BUTTON elFadeRight delay-xl">{ generalText?.projectItem?.button1?.text }</Link>
												</Scene>
											</div>
										}
									</div>
								}

								{
									props.innerPage &&
									<div className="row">
										{
											_.map(props.buttons, (val1, i2) => {
												if (val1.link) {
													return (
														<div key={i2} className={val1.gridClasses}>
															<Scene classToggle="animationTriggered">
																<Link to={slugByPageId(val1.link, lang, routes)} className="BUTTON elFadeRight delay-xl">{ val1.text }</Link>
															</Scene>
														</div>
													)
												}

												if (val1.url) {
													return (
														<div key={i2} className={val1.gridClasses}>
															<Scene classToggle="animationTriggered">
																<div onClick={() => {
																	if (val1.hasWarning) {
																		setPopups({'showExplicitContentPopup': val1.url});
																	} else {
																		onViewUrl(val1.url);
																	}
																}} className="BUTTON elFadeRight delay-xl" rel="noreferrer">{ val1.text }</div>
															</Scene>
														</div>
													)
												}
											})
										}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProjectItem;