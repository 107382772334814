import { SET_ROUTES, STATE_RESET } from './types';

export function setRoutes(data: {[key: string]: any}) {
	return {
		'type': SET_ROUTES,
		...data
	};
}

export function resetRoutes() {
	return {
		'type': STATE_RESET
	};
}