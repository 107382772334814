import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Controller, Scene } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import PageBg from './../../components/page-bg/page-bg';
import ProjectItem from './../../components/project-item/project-item';
import Pagination from './../../components/pagination/pagination';

import './projects.scss';

interface props {
	'pageId': string
}

function Projects(props: props) {
	const navigate = useNavigate();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});
	const [currentProjectPage, setCurrentProjectPage] = useState<number>(1);
	const [totalProjectPages, setTotalProjectPages] = useState<number>(1);
	const [projects, setProjects] = useState<Array<any>>([]);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			getProjects(1);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, routes, props.pageId]);

	const getProjects = (page: number) => {
		api.get('/get-projects/' + page).then((res) => {
			setCurrentProjectPage(page);
			
			if (page === 1 && res.data?.total) {
				setTotalProjectPages(Math.ceil(res.data.total / res.data.entries.length));
			}

			setProjects(res.data.entries);
			
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});
	}
	
	if (_.isEmpty(text)) { return null; }

	return (
		<main className="PAGE PROJECTS">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={ lang } />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={ text.pageDescription } />
			</Helmet>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageBg>
					<section className="SECTION size2 no-border">
						<div className="grid">
							<div className="row full-height vertical-aligned">
								<div className="col-12 col-sm-8 push-sm-2">
									{
										text.preTitle &&
										<Scene classToggle="animationTriggered">
											<div className="SECTION__pretitle text-center elFadeUp delay-xs">
												<span>{ text.preTitle }</span>
											</div>
										</Scene>
									}

									{
										text.title &&
										<div className="row">
											<div className="col-12">
												<Scene classToggle="animationTriggered">
													<h1 className="SECTION__title text-center elFadeUp delay-xs">
														{ text.title }
													</h1>
												</Scene>
											</div>
										</div>
									}

									{
										text.intro?.subtitle &&
										<Scene classToggle="animationTriggered">
											<h2 className="SECTION__subtitle text-center  elFadeUp delay-md" dangerouslySetInnerHTML={{ __html: text.intro?.subtitle }}></h2>
										</Scene>
									}
								</div>
							</div>

							{
								projects?.length > 0 &&
								<div className="row">
									<div className="col-12">
										<div className="SECTION__content">
											<div className="row">
												{
													_.map(projects, (val1, i1) => {
														val1 = {...val1, ...val1[lang]};

														return (
															<div key={i1} className="col-12">
																<div className="SECTION__item">
																	<ProjectItem
																		innerPage={false}
																		reversed={i1 % 2 !== 0}
																		slug={val1.slug}
																		image={val1.image}
																		title={val1.title}
																		type={val1.type}
																		about={val1.about}
																		status={val1.status}
																		buttons={val1.buttons}
																	/>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
									</div>
								</div>
							}

							<div className="row">
								<div className="col-12">
									<div className="SECTION__content">
										<div className="row">
											<div className="col-12">
												<Pagination
													currentPage={currentProjectPage}
													totalPages={totalProjectPages}
													prevText={generalText?.pagination?.prevText}
													nextText={generalText?.pagination?.nextText}
													onChangePage={(val: number) => getProjects(val)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</PageBg>
			</Controller>
		</main>
	);
}

export default Projects;