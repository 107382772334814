import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import _ from 'lodash';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

import './header.scss';

interface props {}

function Header(props: props) {
	const location = useLocation();
	
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	
	const [activeNav, setActiveNav] = useState<boolean>(false);

	useEffect(() => {
		// window.scroll(0, 0);
		setActiveNav(false);
	}, [location]);

	return (
		<header className={'HEADER' + (activeNav ? ' active': '')}>
			<div className="HEADER__wrap">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="HEADER__inner">
								<div className="HEADER__logo">
									<NavLink 
										to={slugByPageId((lang !== 'en' ? '/' + lang : '') + generalText?.header?.logo?.link, lang, routes)}
										className="HEADER__logo-link"
									>
										{
											generalText?.header?.logo?.image &&
											<img src={process.env.PUBLIC_URL + '/' + generalText?.header?.logo?.image} className="logo" alt={generalText?.header?.logo?.alt} />
										}
										
										{
											generalText?.header?.logo?.text1 &&
											<span className="text" dangerouslySetInnerHTML={{ __html: generalText?.header?.logo?.text1 }}></span>
										}
									</NavLink>
								</div>

								<div className="HEADER__content">
									<div className="HEADER__content-item">
										<div className="HEADER__toggler" onClick={() => setActiveNav(!activeNav)}>
											<div className="HEADER__toggler-item"></div>
											<div className="HEADER__toggler-item"></div>
											<div className="HEADER__toggler-item"></div>
											<div className="HEADER__toggler-item"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="HEADER__overflow"></div>

			<div className="HEADER__navigation">				
				<div className="HEADER__navigation-scroll">
					<div className="TABLE">
						<div className="TABLE__row">
							<div className="TABLE__row-cell">
								{
									generalText?.header?.title &&
									<div className="HEADER__navigation-title">
										{ generalText?.header?.title }
									</div>
								}

								{
									generalText?.header?.links &&
									<div className="HEADER__navigation-items">
										{
											_.map(generalText?.header?.links, (val1, i1) => {
												return (
													<div key={i1} className="HEADER__navigation-item">
														<NavLink to={slugByPageId(val1.link, lang, routes)} className={(navData) => navData.isActive ? 'active' : ''}>
															{ val1.text }
														</NavLink>
													</div>
												)
											})
										}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
