import React from 'react';

// Import styles
import './loader.scss';

interface props {
	'fixed'?: boolean,
	'customClass'?: string,
	'active': boolean,
	'noBg'?: boolean
};

const Loader = (props: props) => {
	if (!props.active) { return null; }

	return (
		<span className={'LOADER' + 
			(props.fixed ? ' fixed' : '') +
			(props.active ? ' active' : '') +
			(props.noBg ? ' no-bg' : '') +
			(props.customClass ? ' ' + props.customClass : '')
		}>
			<span className="LOADER__item"></span>
		</span>
	);
}

export default Loader;
