import { createStore, combineReducers } from 'redux';
// import { applyMiddleware, createStore, combineReducers } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

// import monitorReducersEnhancer from './monitor-reducers';
// import loggerMiddleware from './logger';

// Import reducers
import lang from './lang';
import routes from './routes';
import generalText from './general-text';

export default function configureStore(preloadedState: any) {
	// const middlewares = [loggerMiddleware, thunkMiddleware]
	// const middlewareEnhancer = applyMiddleware(...middlewares)

	// const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
	// const composedEnhancers: any = composeWithDevTools(...enhancers)

	const store = createStore(combineReducers({
		lang,
		routes,
		generalText
	}), preloadedState)
	// }), preloadedState, composedEnhancers)

	return store
}