import { SET_GENERAL_TEXT, STATE_RESET } from './../actions/types';

export function setGeneralText(data: {[key: string]: any}) {
	return {
		'type': SET_GENERAL_TEXT,
		...data
	};
}

export function resetGeneralText() {
	return {
		'type': STATE_RESET
	};
}