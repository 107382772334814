import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

interface props {
	'pageId'?: string
}

const Response = (props: props) => {
	const navigate = useNavigate();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	
	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		if (!props.pageId) { return; }

		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onAcceptCookies = () => {
		// Set cookie for 365 days
		let date = new Date();
		date.setTime(+ date + (365 * 86400000));

		document.cookie = 'cookiesAccepted=true;expires=' + date.toUTCString() + '; path=/';

		navigate(slugByPageId('main', lang, routes));
	}

	const onDeclineCookies = () => {
		// Clear localstorage
		localStorage.clear();

		// Clear cookies
		let cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i];
			let eqPos = cookie.indexOf('=');
			let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}

		window.location.replace("https://www.google.com");
	}

	return (
		<div className="PAGE">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
						
			<div className="SECTION size2 no-border text-center">
				<div className="grid">
					<div className="row">
						<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
							{
								!props.pageId &&
								<div className="SECTION__icon">
									<div className="icon-info-regular"></div>
								</div>
							}

							{
								!props.pageId &&
								<div className="SECTION__title">Something went wrong</div>
							}

							{
								!props.pageId &&
								<div className="SECTION__subtitle">Please refresh the site</div>
							}

							{
								!props.pageId &&
								<p>If the problem persists, please contact me at&nbsp;
									{
										process.env?.REACT_APP_ENV?.includes('azukaitis') &&
										<a href="mailto:aivaras@azukaitis.com" className="LINK rtl">moc.sitiakuza@saravia</a>
									}

									{
										process.env?.REACT_APP_ENV?.includes('azhuko') &&
										<a href="mailto:hello@azhuko.com" className="LINK rtl">moc.okuhza@olleh</a>
									}
								</p>
							}

							{
								text.icon &&
								<div className="SECTION__icon">
									<div className={'icon-' + text.icon}></div>
								</div>
							}

							{
								text.title &&
								<div className="SECTION__title">{ text.title }</div>
							}

							{
								text.subtitle &&
								<div className="SECTION__subtitle">{ text.subtitle }</div>
							}


							{
								text.text &&
								<div dangerouslySetInnerHTML={{ __html: text.text }}></div>
							}

							{
								text.buttons?.length > 0 &&
								<div className="col-12">
									<div className="SECTION__content">
										<div className="row">
											{
												_.map(text.buttons, (val1, i1) => {
													return (
														<div key={i1} className={val1.gridClasses}>
															{
																val1.isAcceptCookies &&
																<span onClick={() => onAcceptCookies()}className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>
																	{ val1.text }
																</span>
															}

															{
																val1.isDeclineCookies &&
																<span onClick={() => onDeclineCookies()}className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>
																	{ val1.text }
																</span>
															}

															{
																!val1.isAcceptCookies && 
																!val1.isDeclineCookies &&
																<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>
																	{ val1.text }
																</Link>
															}
														</div>
													)
												})
											}
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Response;