import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// Import middlewares
import api from './other/middlewares/api';

// Import actions
import { setRoutes } from './other/actions/routes';

// Import helpers
import { setCookie, checkCookie } from './other/helpers/cookies';

// Import pages
import MainLayout from './main-layout';

import About from './pages/about/about';
import Pricing from './pages/pricing/pricing';
import Projects from './pages/projects/projects';
import Project from './pages/project/project';
import Contacts from './pages/contacts/contacts';

import Text from './pages/text/text';
import Response from './pages/response/response';

// Import components
import Popup from './components/popup/popup';

import './assets/styles/style.scss';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();

	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isRoutesLoaded, setIsRoutesLoaded] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<{[key: string]: any}>({'title': '', 'text': []});

	useEffect(() => {
		window.scrollTo({'top': 0, 'behavior': 'smooth'});
	}, [location]);

	useEffect(() => {
		if (!checkCookie('webpSupported')) {
			let elem = document.createElement('canvas');

			if (!!(elem.getContext && elem.getContext('2d'))) {
				// was able or not to get WebP representation
				if (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
					setCookie('webpSupported', 'true', 7);
				}
			} else {
				// very old browser like IE 8, canvas not supported
				setCookie('webpSupported', 'false', 7);
			}
		}
	}, []);

	useEffect(() => {
		api.get('/get-pages-routes').then((res) => {
			dispatch(setRoutes({'routes': res.data}))
			setIsRoutesLoaded(true);
		}).catch((err) => {
			if (err.message) {
				setNotifications({'title': 'Connection problem', 'text': ['Please check your internet connection and refresh the site. If the problem persists, please come back later.']});
			} else {
				setNotifications(err.response?.data?.messages);
			}

			setIsRoutesLoaded(true);
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isRoutesLoaded) { return null; }

	return (
		<>
			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #64296234823']}
				closeBtn={generalText?.generalPopup?.button1?.text || 'Close'}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Routes>
				{
					!_.isEmpty(routes) &&
					_.map(routes, (val1, i1) => {
						let langPath = (i1 !== 'en' ? '/' + i1 : '');

						return (
							<Route key={i1} path={langPath + '/*'} element={<MainLayout lang={i1} />}>
								{
									_.map(val1, (val2, i2) => {
										if (!val2.component) { return null; }
										
										switch(val2.component) {
											case 'About': return <Route key={i2} path={val2.slug} element={<About pageId={val2.pageId} />} />;
											case 'Pricing': return <Route key={i2} path={val2.slug} element={<Pricing pageId={val2.pageId} />} />;
											case 'Projects': return <Route key={i2} path={val2.slug} element={<Projects pageId={val2.pageId} />} />;
											case 'Project': return <Route key={i2} path={val2.slug} element={<Project pageId={val2.pageId} />} />;
											case 'Contacts': return <Route key={i2} path={val2.slug} element={<Contacts pageId={val2.pageId} />} />;
											case 'Text': return <Route key={i2} path={val2.slug} element={<Text pageId={val2.pageId} />} />;
											case 'Response': return <Route key={i2} path={val2.slug} element={<Response pageId={val2.pageId} />} />;
										}
									})
								}

								<Route path="*" element={<Navigate to={langPath + '/404'} replace={true} />} />
							</Route>
						)
					})
				}

				{
					_.isEmpty(routes) &&
					<Route path="*" element={<Response />} />
				}
			</Routes>
		</>
	);
}

export default App;
