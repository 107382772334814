import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Controller, Scene } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import PageBg from './../../components/page-bg/page-bg';

import './pricing.scss';

interface props {
	'pageId': string
}

function Pricing(props: props) {
	const navigate = useNavigate();
	
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': false});
		});

		return () => {
			setText({});
		};
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, routes, props.pageId]);

	if (_.isEmpty(text)) { return null; }

	return (
		<main className="PAGE PRICING">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={ lang } />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={ text.pageDescription } />
			</Helmet>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageBg>
					<section className="SECTION size2 no-border">
						<div className="grid">
							<div className={'row vertical-aligned' + (text.position === 'right' ? ' reversed-cols' : '')}>
								<div className={'col-12 col-md-4' + (text.position === 'center' ? ' push-md-4 text-center' : '')}>
									{
										text.preTitle &&
										<Scene classToggle="animationTriggered">
											<div className="SECTION__pretitle text-center elFadeUp delay-xs">
												<span>{ text.preTitle }</span>
											</div>
										</Scene>
									}

									{
										text.title &&
										<Scene classToggle="animationTriggered">
											<h3 className={'SECTION__title elFadeUp delay-sm' + (text.position === 'center' ? ' centered' : '')}>
												{ text.title }
											</h3>
										</Scene>
									}

									{
										text.subtitle &&
										<Scene classToggle="animationTriggered">
											<h4 className={'SECTION__subtitle elFadeUp delay-md' + (text.position === 'center' ? ' centered' : '')} dangerouslySetInnerHTML={{__html: text.subtitle}}></h4>
										</Scene>
									}

									{
										text.text &&
										<Scene classToggle="animationTriggered">
											<div className={'elFadeUp delay-md' + (text.position === 'center' ? ' centered' : '')} dangerouslySetInnerHTML={{__html: text.text}}></div>
										</Scene>
									}
								</div>

								<div className={'col-12 col-md-8' + (text.position === 'center' ? ' push-md-2 text-center' : '')}>
									<div className="SECTION__content">
										<Scene classToggle="animationTriggered">
											<div className="SECTION__item elFadeUp delay-lg">
												<div className="PRICING__table">
													<div className="PRICING__table-inner">
														{
															_.map(text.pricing, (val1, i1) => {
																return (
																	<div key={i1} className="PRICING__table-row">

																		{
																			_.map(val1.column, (val2, i2) => {
																				return (
																					<div key={i2} className="PRICING__table-row-cell">
																						{
																							val2.type === 'heading' && 
																							val2.value &&
																							<h3 className="PRICING__price" dangerouslySetInnerHTML={{__html: val2.value}}></h3>
																						}

																						{
																							val2.type === 'text' && 
																							val2.value &&
																							<div className="PRICING__text" dangerouslySetInnerHTML={{__html: val2.value}}></div>
																						}

																						{
																							val2.type === 'icon' && 
																							val2.value &&
																							<div className={'PRICING__icon ' + val2.value}>
																								<div className={'icon-' + val2.value}></div>
																							</div>
																						}
																					</div>
																				)
																			})
																		}
																	</div>
																)
															})
														}
													</div>
												</div>
											</div>
										</Scene>
									</div>

									{
										text.text1 &&
										<div className="PRICING__notes" dangerouslySetInnerHTML={{__html: text.text1}}></div>
									}

									{
										text.buttons?.length > 0 &&
										<div className="SECTION__content">
											<div className="row">
												{
													_.map(text.buttons, (val2, i1) => {
														return (
															<div key={i1} className={val2.gridClasses}>
																<Scene classToggle="animationTriggered">
																	<Link to={slugByPageId(val2.link, lang, routes)} className={'BUTTON elFadeUp delay-lg' + (val2.diff ? ' ' + val2.diff : '') + (val2.side === 'center' ? ' centered' : '')}>{ val2.text }</Link>
																</Scene>
															</div>
														)
													})	
												}
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					</section>
				</PageBg>
			</Controller>
		</main>
	);
}

export default Pricing;
